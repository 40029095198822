import React, { FC, memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AccordionItem from '@components/accordion/AccordionItem';
import Select from '@components/form/Select';
import { GENERATORS_SOURCE_FORM, MODEL } from '@components/custom-flows/generators-source/contants';
import CombinedInput from '@components/form/CombinedInput';

import GeneratorsFiles from '@components/custom-flows/generators-source/GeneratorsFiles';
import RemoveButton from '@components/custom-flows/heat-source/RemoveButton';
import { GeneratorsSourceItem as GeneratorsSourceItemType } from '@components/custom-flows/generators-source/types';
import TextInput from '@components/form/TextInput';
import ControlledNumericInput from '@components/form/ControlledNumericInput';
import ControlledSwitch from '@components/form/ControlledSwitch';

interface Props {
  index: number;
  open: boolean;
  onToggle: () => void;
  removable?: boolean;
  onRemove?: () => void;
}

const INITIAL_TITLE = 'Bestandsanlagen';

const GeneratorsSourceItem: FC<Props> = ({ index, removable, onRemove, open, onToggle }) => {
  const { getValues } = useFormContext<{ existing_generators: GeneratorsSourceItemType[] }>();
  const [title, setTitle] = useState<string | null>(INITIAL_TITLE);

  const blockTitle = `${index + 1}. ${title}`;

  return (
    <AccordionItem title={blockTitle} open={open} onToggle={onToggle}>
      <Select
        category={''}
        page={''}
        model={MODEL}
        className="block mb-1"
        onChange={value => {
          if (value) {
            const fields = getValues('existing_generators').filter(field => field.type_gen === value);
            setTitle(`${value} ${fields.length > 1 ? fields.length : ''}`);
            return;
          }
          setTitle(INITIAL_TITLE);
        }}
        {...GENERATORS_SOURCE_FORM.type_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.type_gen.name}`}
        accordion
      />

      <ControlledNumericInput
        page={''}
        model={MODEL}
        category={''}
        {...GENERATORS_SOURCE_FORM.max_power_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.max_power_gen.name}`}
        className="block input-short spacing"
        accordion
      />
      <ControlledNumericInput
        page={''}
        model={MODEL}
        category={''}
        maxLabelInput
        className="block input-short"
        {...GENERATORS_SOURCE_FORM.total_yield_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.total_yield_gen.name}`}
        accordion
      />

       <ControlledSwitch
        page={''}
        model={MODEL}
        category={''}
        className="mt-5 mb-5"
        {...GENERATORS_SOURCE_FORM.self_use_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.self_use_gen.name}`}
        accordion
      />

      {getValues(`existing_generators.${index}.self_use_gen`) &&<ControlledNumericInput
        page={''}
        model={MODEL}
        className="block input-short"
        category={''}
        {...GENERATORS_SOURCE_FORM.evq_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.evq_gen.name}`}
        accordion
      />}

      <TextInput
        page={''}
        model={MODEL}
        category={''}
        maxLabelInput
        className="block input-short"
        {...GENERATORS_SOURCE_FORM.startfeedin_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.startfeedin_gen.name}`}
        accordion
      />


      <ControlledNumericInput
        page={''}
        model={MODEL}
        className="block input-short"
        category={''}
        maxLabelInput
        {...GENERATORS_SOURCE_FORM.eeg_subsidy_old_gen}
        name={`${index}.${GENERATORS_SOURCE_FORM.eeg_subsidy_old_gen.name}`}
        accordion
      />



      <hr className="border-t-2 mt-4 mb-4 border-[--grey-scale-200]" />

      <GeneratorsFiles index={index} />
      {removable && onRemove && <RemoveButton block={blockTitle} onRemove={onRemove} />}
    </AccordionItem>
  );
};

export default memo(GeneratorsSourceItem);
