import React, { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { GeneratorsSourceItem } from '@components/custom-flows/generators-source/types';
import { GENERATORS_SOURCE_FORM, MODEL } from '@components/custom-flows/generators-source/contants';
import ControlledSwitch from '@components/form/ControlledSwitch';
import FileUploader from '@components/form/file-uploader/FileUploader';

interface Props {
  index: number;
}

const GeneratorsFiles: FC<Props> = ({ index }) => {
  const { watch } = useFormContext<{ existing_generators: GeneratorsSourceItem[] }>();
  const isUploadFiles = watch(`existing_generators.${index}.isUploadFiles`);
  const technology = watch(`existing_generators.${index}.type_gen`);
  const id = watch(`existing_generators.${index}.id_gen`);

  return (
    <div>
      <ControlledSwitch
        page={''}
        model={MODEL}
        category={''}
        className="mb-8"
        {...GENERATORS_SOURCE_FORM.isUploadFiles}
        name={`${index}.${GENERATORS_SOURCE_FORM.isUploadFiles.name}`}
        accordion
      />

      {isUploadFiles && (
        <>
          <FileUploader
            page={''}
            model="load_profile_upload"
            upload_id={`${technology}_${id}_generators_production`}
            category={''}
            className="mb-8"
            {...GENERATORS_SOURCE_FORM.gen_load_profile_upload}
            name={`${MODEL}.${index}.${GENERATORS_SOURCE_FORM.gen_load_profile_upload.name}`}
          />
          <FileUploader
            page={''}
            model="load_profile_upload"
            category={''}
            upload_id={`${technology}_${id}_generators_feedin`}
            {...GENERATORS_SOURCE_FORM.gen_load_profile_upload_second}
            name={`${MODEL}.${index}.${GENERATORS_SOURCE_FORM.gen_load_profile_upload_second.name}`}
          />
        </>
      )}
    </div>
  );
};

export default memo(GeneratorsFiles);
