import React, { FC, memo, useMemo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';
import Dropdown from '@components/inputs/Dropdown';

interface Props extends Field {
  className?: string;
  onChange?: (value: string) => void;
  accordion?:boolean;
}

const Select: FC<Props> = ({ className, onChange, max_width, accordion, ...props }) => {
  const style = useMemo(() => ({ ...(max_width && { maxWidth: max_width }) }), [max_width, max_width]);

  return (
    <FieldController
      name={`${props.model}.${props.name}`}
      q={props}
      type={props.type}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <InputLabel inputStyle={style} q={props} className={`${className} text-[white]`} accordion={accordion?accordion:false}>
              <Dropdown
                {...field}
                onChange={value => {
                  field.onChange(value);
                  onChange?.(value);
                }}
                error={!!error}
                options={props.options}
              />
            </InputLabel>
            <div className="mt-2 mb-2">{error && <InputError error={error.message} />}</div>
          </div>
        );
      }}
    />
  );
};

export default memo(Select);
