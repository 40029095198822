import React, { FC, memo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';
import { IMaskInput } from 'react-imask';
import Switch from '@components/inputs/Switch';
import classNames from 'classnames';

interface Props extends Field {
  className?: string;
  accordion?:boolean;
}
const ControlledSwitch: FC<Props> = props => {
  const { className, type, name,accordion, ...q } = props;

  return (
    <FieldController
      name={`${q.model}.${name}`}
      q={q}
      type={type}
      render={({ field: { value, ...field }, fieldState: { error } }) => {
        return (
          <InputLabel
            q={props}
            inputClassName="flex items-center"
            className={classNames('flex items-center justify-between cursor-pointer mb-0', { [className!]: className })}
            accordion={accordion?accordion:false}
          >
            <Switch checked={!!value} {...field} id={props.name} />
          </InputLabel>
        );
      }}
    />
  );
};
export default memo(ControlledSwitch);
