import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Field } from '@/types';
import classNames from 'classnames';

interface Props {
  q: Field;
  className?: string;
  inputClassName?: string;
  inputStyle?: CSSProperties;
  maxLabelInput?:boolean;
  accordion?:boolean;
}

const InputLabel: FC<PropsWithChildren<Props>> = ({ q, children, inputStyle, className, inputClassName, maxLabelInput,accordion }) => {
  return (
    <label style={accordion?{color:'var(--accordion-text-color)'}:{}} key={q.name} htmlFor={q.name} className={classNames('input-label', { [className!]: className },`${maxLabelInput?'max-w-full':''}`)}>
      <span>
        <span dangerouslySetInnerHTML={{ __html: q.label ?? '' }} />
        {q.is_required && '*'}
        {q.description && (
          <span className="tooltip tooltip-right" data-tip={q.description}>
            <InformationCircleIcon className="icon-color" />
          </span>
        )}
      </span>
      <div style={inputStyle} className={classNames('relative', { [inputClassName!]: inputClassName },`${maxLabelInput?className:''}`)}>
        {children}
      </div>
    </label>
  );
};

export default InputLabel;
