import React, { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HeatSourceItem } from '@components/custom-flows/heat-source/types';
import { HEAT_SOURCE_FORM, MODEL } from '@components/custom-flows/heat-source/contants';
import ControlledSwitch from '@components/form/ControlledSwitch';
import FileUploader from '@components/form/file-uploader/FileUploader';

interface Props {
  index: number;
}

const HeatSourceFile: FC<Props> = ({ index }) => {
  const { watch } = useFormContext<{ heat_information: HeatSourceItem[] }>();
  const isUploadFile = watch(`heat_information.${index}.isUploadFile`);
  const technology = watch(`heat_information.${index}.source_heat`);
  const id = watch(`heat_information.${index}.id_heat`);

  return (
    <div>
      <ControlledSwitch
        page={''}
        model={MODEL}
        category={''}
        className="mb-8"
        {...HEAT_SOURCE_FORM.isUploadFile}
        name={`${index}.${HEAT_SOURCE_FORM.isUploadFile.name}`}
        accordion
      />

      {isUploadFile && (
        <FileUploader
          page={''}
          model="load_profile_upload"
          upload_id={`${technology}_${id}_loadprofile`}
          category={''}
          className="mb-8"
          {...HEAT_SOURCE_FORM.loadprofile}
          name={`${MODEL}.${index}.${HEAT_SOURCE_FORM.loadprofile.name}`}
        />
      )}
    </div>
  );
};

export default memo(HeatSourceFile);
