import MainContainer from '@shared/MainContainer';
import SurveySubtitle from '@components/SurveySubtitle';
import CategoryTitle from '@components/CategoryTitle';
import ProgressBar from '@components/ProgressBar';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '@components/form/Form';
import { CURRENT_CONFIG } from '@/config';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ArrowRight from '@components/ArrowRight';
import ChevronBack from '@components/ChevronBack';
import ArrowLeft from '@components/ArrowLeft';
import { initial_daily, initial_monthly } from '@components/custom-flows/load-profile/helpers';
import { v7 as uuidv7 } from 'uuid';
import usePostData from '@hooks/usePostData';
import useGenerateReport from '@hooks/useGenerateReport';
import { getDefaultValues } from '@/helpers/getDefaultValues';
import Report from '@components/Report';
import { useTheme } from '@/context/ThemeProvider';
import GridContainer from '@components/GridContainer';
import { VITE_NODE_ENV } from '@utils/getEnv';
import { useFormLock } from '@/hooks/useFormLock';
import { sessionStorage } from '@/helpers/isSessionValid';
import ApiService from '@/api';
import { METHODS } from '@constants/api';

// const reportFileGenerationEnabled = JSON.parse(VITE_NODE_ENV['VITE_ENABLE_REPORT_GENERATION']);
const pages = CURRENT_CONFIG.pages;

function App() {
  const searchParams = new URLSearchParams(document.location.search);
  const { theme } = useTheme();
  const uuid = useRef(searchParams.get('uuid') || uuidv7());
  const [savingProgress, setSavingProgress] = useState<boolean>(false);
  const [showCheck, setShowCheck] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [skipWarn, setSkipWarn] = useState(!searchParams.get('uuid'));
  const [pageHistory, setPageHistory] = useState<number[]>([]); // Tracks all visited pages
  const [visitedPage, setVisitedPage] = useState<number[]>([0]); // Tracks all visited pages
  const [sendModels, { loading: loadingModels, isFetched, error: modelsError }] = usePostData();
  const [generateReport, { data: report, error: reportError }] = useGenerateReport();
  const currentPage = pages[page];

  useEffect(() => {
    const max = Math.max(page, maxPage);

    if (max != maxPage) {
      setMaxPage(max);
    }
  }, [page, maxPage]);

  const form = useForm<Record<string, string | Record<string, any>>>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      uuid: uuid.current,
      ...getDefaultValues(pages),
      load_profile_upload: [],
      load_profile: {
        daily_profile: initial_daily.join(','),
        monthly_profile: initial_monthly.join(','),
      },
      heat_information: [],
      existing_generators: [],
      filesLoaded: {}
    },
  });

  useEffect(() => {
    const docUuid = searchParams.get('uuid')
    if (docUuid) {
      const endpoint = `/upsert?uuid=${docUuid}`;

      ApiService.request({
        endpoint,
        method: METHODS.GET,
      }).then((response) => {
        const data: Record<any, any> = response as Record<any, any>;

        Object.keys(data).forEach((model) => {
          const { uuid, step, ...values} = data[model];

          if (model === 'load_profile') {
            if (data[model]?.data_uploaded === 'true') {
              form.setValue(`filesLoaded.load_profile_upload`, true);
            }
          }

          if (model === 'heat_information') {
            if (Array.isArray(data[model])) {
              const heatData = data[model].map((heat, index) => {
                const {
                  uuid,
                  model_name,
                  ...rest
                } = heat;
                let isGenerateProcessHeat = false;
                let isUploadFile = false;

                if (rest.max_temp_process_heat || rest.max_power_process_heat) {
                  isGenerateProcessHeat = true;
                }

                if (rest?.data_uploaded === 'true') {
                  isUploadFile = true;

                  form.setValue(`filesLoaded.filesLoaded.heat_information.${index}.loadprofile`, true);
                }

                return {
                  ...rest,
                  isGenerateProcessHeat,
                  isUploadFile,
                }
              })

              form.setValue(`${model}`, heatData);
            }

            return;
          }

          if (model === 'existing_generators') {
            if (Array.isArray(data[model])) {
              const genData = data[model].map((gen, index) => {
                const {
                  uuid,
                  model_name,
                  data_uploaded1,
                  data_uploaded2,
                  startfeedin_gen,
                  ...rest
                } = gen;

                if (data_uploaded1 === 'true') {
                  form.setValue(`filesLoaded.existing_generators.${index}.gen_load_profile_upload_second`, true);
                }

                if (data_uploaded2 === 'true') {
                  form.setValue(`filesLoaded.existing_generators.${index}.gen_load_profile_upload`, true);
                }

                return {
                  ...rest,
                  ...(!!startfeedin_gen && { startfeedin_gen })
                }
              })

              form.setValue(`${model}`, genData);
            }

            return;
          }

          Object.keys(values).forEach(key => {
            if (key === 'steps_skipped') {
              values?.[key]?.split(',')?.forEach((stepsSkipped: string) => {
                const [, name, val] = stepsSkipped.split('+');

                if (name && val) {
                  form.setValue(`transient.${name}`, JSON.parse(val === 'undefined' ? 'false' : val));
                }
              })

              return;
            }

            if (key === 'steps_completed') {
              const history = values[key]?.split(',');

              if (history && history.length > 0) {
                const pages = history.map((v: string) => +v);

                setMaxPage(Math.max(...pages));
                setPage(Math.max(...pages));
                setVisitedPage(pages);
                setPageHistory(pages);
              }

              return;
            }

            form.setValue(`${model}.${key}`, values[key] === '0' ? '' : values[key]);
          })
        })
      })
    }
  }, []);

  const { isLocked } = useFormLock({ control: form.control });

  const saveProgress = useCallback(async () => {
    const progressData: Record<any, any> = {
      'meta_information': {
        uuid: uuid.current,
        model_name: 'meta_information',
        steps_skipped: [],
        steps_completed: visitedPage.join(',')
      },
    };

    setSavingProgress(true);

    const endpoint = '/upsert/'

    visitedPage.forEach(pageIndex => {
      const prevPageData = pages[pageIndex];

      if (prevPageData) {
        const fields = prevPageData.qns;

        fields.forEach(f => {
          if (f.model === 'transient') {
            const value = form.getValues(`${f.model}.${f.name}`);
            progressData['meta_information'].steps_skipped.push(`${pageIndex}+${f.name}+${value}`);

            return;
          }

          if (f.model === 'load_profile_upload') {
            const value: Record<any, any> = form.getValues('load_profile_upload') as Record<any, any>;

            if (value?.isError) {
              return;
            }

            if (!progressData?.load_profile) {
              progressData.load_profile = {
                uuid: uuid.current,
                model_name: 'load_profile',
              }
            } else {
              progressData.load_profile = {
                ...progressData.load_profile,
                data_uploaded: String(!!value?.uploaded),
              }
            }

            return;
          }

          if (f.model === 'heat_information') {
            const heat_information: any = form.getValues(f.model);

            if (Array.isArray(heat_information) && heat_information.length > 0) {
              heat_information?.map(
                ({
                   cost_unit_heat,
                   id_heat,
                   source_heat,
                   total_consumption_heat,
                   unit_heat,
                   loadprofile,
                   ...rest
                 }) => {
                  return ({
                    cost_unit_heat: +cost_unit_heat,
                    id_heat,
                    source_heat,
                    total_consumption_heat,
                    unit_heat,
                    uuid: uuid.current,
                    model_name: f.model,
                    data_uploaded: String(loadprofile?.some((v: any) => !!v?.uploaded) || false),
                    ...rest,
                  });
                }).forEach(heat => {
                  ApiService.request({
                    endpoint,
                    method: METHODS.POST,
                    body: heat
                  })
                })
            }

            return;
          }

          if (f.model === 'existing_generators') {
            const existing_generators: any = form.getValues(f.model);

            if (Array.isArray(existing_generators) && existing_generators.length > 0) {
              existing_generators?.map(gen => {
                const { isUploadFiles, gen_load_profile_upload_second, gen_load_profile_upload, startfeedin_gen, ...rest } = gen;

                return {
                  ...rest,
                  uuid: uuid.current,
                  model_name: f.model,
                  ...startfeedin_gen && ({ startfeedin_gen }),
                  ...isUploadFiles && ({
                    data_uploaded_1: String(!!gen_load_profile_upload?.uploaded),
                    data_uploaded_2: String(!!gen_load_profile_upload_second?.uploaded),
                  })
                }
              }).forEach(gen => {
                ApiService.request({
                  endpoint,
                  method: METHODS.POST,
                  body: gen
                })
              })
            }

            return;
          }

          if (!progressData?.[f.model]) {
            progressData[f.model] = {
              uuid: uuid.current,
              model_name: f.model,
            }
          }

          progressData[f.model] = {
            ...progressData[f.model],
            [f.name]: form.getValues(`${f.model}.${f.name}`)
          }
        })
      }
    })

    progressData['meta_information'].steps_skipped = progressData['meta_information'].steps_skipped.join(',');

    await Promise.allSettled(Object.values(progressData).map(d => {
      return ApiService.request({
        endpoint,
        method: METHODS.POST,
        body: d
      })
    }))

    await Promise.allSettled([ (()=>{
      const user=VITE_NODE_ENV['VITE_USER_API_PARAMS']
      return ApiService.request({
        endpoint:`/safe_data/?uuid=${uuid.current}&user=${user}`,
        method: METHODS.POST,
        body: { uuid:uuid.current } as any
      })
    })()])

    setShowCheck(true);

    if (window.history.pushState) {
      const url = window.location.protocol + "//" + window.location.host + window.location.pathname + `?uuid=${uuid.current}`;

      window.history.pushState({path:url},'',url);
    }

    setSavingProgress(false);

    setTimeout(() => {
      setShowCheck(false)
    }, 1500)
  }, [form, visitedPage])

  const onNext = useCallback(
    (d: Record<string, string | Record<string, any>>) => {
      if (page >= pages.length - 1) {
        return;
      }

      const nextStep = CURRENT_CONFIG.skipRules[currentPage.name as keyof (typeof CURRENT_CONFIG)['skipRules']]?.(d);

      setPage(prev => {
        setPageHistory(p => (p.at(-1) !== prev ? [...p, prev] : p));

        const value = nextStep > 0 ? nextStep : prev + 1;

        setVisitedPage((prev) => [...prev, value].filter((item, pos, self) => self.indexOf(item) == pos));

        return value;
      });
    },
    [page, currentPage],
  );

  const onPrev = useCallback(() => {
    if (pageHistory.length) {
      setPage(pageHistory.at(-1)!);
      setPageHistory(prev => prev.slice(0, -1));
    }
  }, [pageHistory]);

  const progress = (100 * page) / pages.length + 1;
  const maxPageProgress = (100 * maxPage) / pages.length + 1;

  const onSubmit = useCallback(
    async (data: Record<string, string | Record<string, any>>) => {
      const { uuid, filesLoaded, transient, load_profile_upload, heat_information, existing_generators, ...restData } = data;

      // Send data on the final step
      if (page >= pages.length - 1) {
        sessionStorage.setSession(uuid as string);
        const models = Object.keys(restData);

        await Promise.all([
          ...models.map(m => {
            const model = restData[m];

            return sendModels(`upsert`, { ...(typeof model !== 'string' && model), uuid,model_name:m });
          }),
          ...(heat_information as Record<string, string | number>[])?.map(
            ({
              cost_unit_heat,
              id_heat,
              source_heat,
              total_consumption_heat,
              unit_heat,
              isGenerateProcessHeat,
              ...rest
            },index) => {
              const loadprofile=form.getValues(`f.model.${index}.loadprofile`);
              return sendModels('upsert', {
                cost_unit_heat,
                id_heat,
                source_heat,
                total_consumption_heat,
                unit_heat,
                uuid,
                model_name:'heat_information',
                data_uploaded: String(loadprofile?.some((v: any) => !!v?.uploaded) || false),
                isGenerateProcessHeat,
                ...rest
              });
            },
          ),
          ...(existing_generators as Record<string, string | number>[])?.map(
            ({ isUploadFiles, gen_load_profile_upload_second, gen_load_profile_upload, ...rest }) => {
              return sendModels('upsert', { uuid,model_name:'existing_generators', ...rest });
            },
          ),
        ]);

        // TODO: Enable based on requirement of report generation
        // if (reportFileGenerationEnabled) {
        //   await generateReport(uuid);
        // }
        generateReport(uuid);

        return;
      }

      onNext(data);
    },
    [page],
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        form.handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [page]);

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        {isFetched ? (
          <Report error={modelsError || reportError} reportUrl={report?.url} />
        ) : (
          <div className="flex flex-col min-h-dvh max-h-dvh overflow-y-scroll relative">
            <MainContainer>
              <SurveySubtitle />
              <CategoryTitle qns={currentPage.qns} category={currentPage.category} name={currentPage.name} />
              <Form inputs={currentPage.qns} />

              <div className="static md:fixed right-6 bottom-[100px] flex items-end justify-end">
                <button type="button" onClick={saveProgress} disabled={savingProgress || showCheck}
                        //className="box-border flex flex-row justify-center items-center px-4 py-2 gap-2 h-10 bg-white border-2 border-gray-300 rounded-[var(--button-border-radius)] disabled:bg-gray-300">
                        className="box-border flex flex-row justify-center items-center px-4 py-2 gap-2 h-10 bg-[var(--save-button-background)] rounded-[var(--save-button-radius)] disabled:bg-gray-300">
                  {showCheck ? <svg className="h-8 w-8 text-cyan-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 12l5 5l10 -10" /></svg> : savingProgress ?
                    <span className="loading loading-ring loading-sm"></span> :
                    <svg width="0" height="0" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H5V15C5 13.3431 6.34315 12 8 12H16C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14H8C7.44772 14 7 14.4477 7 15V21H17ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
                          fill="#94A3B8" />
                  </svg>}
                  {showCheck ? 'Email versandt' : 'Später fortsetzen'}
                </button>
              </div>
            </MainContainer>

            {!skipWarn && <div className="fixed max-md:top-0 md:bottom-[90px] z-10 w-full left-0 right-0 gap-4 py-4" style={{
              backgroundColor: 'var(--progress-primary)'
            }}>
              <div className="container mx-auto flex md:flex-row flex-col items-center justify-center gap-4">
                <p className="text-md text-white font-bold md:px-0 px-8 md:text-left text-center">
                  Ihr Fortschritt wurde wiederhergestellt.
                </p>
                <div className="flex flex-row items-center gap-4">
                  <button type="button"
                          onClick={() => {
                            window.location.href = '/';
                          }}
                          className="font-bold box-border flex flex-row justify-center items-center px-8 py-4 gap-2 h-14 bg-white border-2 border-gray-300 rounded-md disabled:bg-gray-300">
                    Neu starten
                  </button>
                  <button type="button"
                          onClick={() => setSkipWarn(true)}
                          className="font-bold box-border flex flex-row justify-center items-center px-8 py-4 gap-2 h-14 bg-white border-2 border-gray-300 rounded-md disabled:bg-gray-300">
                    Dateneingabe weiterführen
                  </button>
                </div>
              </div>
            </div>}

            <footer
              className="flex w-full min-h-[90px] bg-white shadow-lg border-t-2 border-[var(--subtitle-color)] sticky bottom-0 px-10 max-md:px-4 z-10">
              <GridContainer className="m-auto w-full h-full content-center gap-3 max-md:gap-x-0 max-md:grid-cols-2">
                <div className="justify-self-start max-md:col-span-1  max-md:row-start-2 max-md:row-span-1">
                  <button
                    className={`btn-go-back ${theme !== 'arup' ? 'btn-secondary btn-width' : 'btn-black'}`}
                    onClick={onPrev}
                    color="secondary"
                    type="button"
                    disabled={isLocked}
                    {...(page === 0 && {
                      style: {
                        visibility: 'hidden'
                      }
                    })}
                  >
                    {theme !== 'arup' ? (
                      <div className="flex gap-1 items-center">
                        <ChevronBack />
                        <p>Zurück</p>
                      </div>
                    ) : (
                      <ArrowLeft />
                    )}
                  </button>
                </div>
                <div className="justify-self-center w-full max-w-[560px] max-md:col-span-full max-md:max-w-full">
                  <ProgressBar
                    pages={pages}
                    progressLength={pages.length - 1}
                    progress={progress}
                    navigate={(step: number) => {
                      if (visitedPage.includes(step) || step === maxPage) {
                        setPage(step);
                      }
                    }}
                    pageHistory={visitedPage}
                    maxPageProgress={maxPageProgress}
                    currentPage={page}
                    maxPage={maxPage}
                  />
                </div>
                <div className="justify-self-end max-md:col-span-2 max-md:row-start-2 max-md:row-span-1">
                  <button className="btn-next next" type="submit" disabled={loadingModels || isLocked}>
                    {theme !== 'arup' ? (
                      <p>Weiter</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <p>Weiter</p>
                        <ArrowRight />
                      </div>
                    )}
                  </button>
                </div>
              </GridContainer>
            </footer>
          </div>
        )}
      </FormProvider>
    </form>
  );
}

export default App;
