export const MODEL = 'existing_generators';

export const GENERATORS_SOURCE_FORM = {
  type_gen: {
    name: 'type_gen',
    type: 'select',
    is_required: true,
    placeholder: 'Bitte wählen Sie',
    label: 'Art der Bestandsanlage',
    options: ['Photovoltaik', 'BHKW', '(Klein-) Windenergieanlage', 'Biomasse', 'Pyrolyse', 'Sonstige'],
  },
  max_power_gen: {
    name: 'max_power_gen',
    type: 'float',
    is_required: true,
    min: 1,
    max: 10000,
    label: 'Anlagenleistung ',
    description: 'Die maximale Leistung ihrer Anlage in kWp.',
    unit: 'kWp',
  },
  total_yield_gen: {
    name: 'total_yield_gen',
    type: 'integer',
    is_required: true,
    min: 1,
    max: 10000000,
    label: 'Produzierte Strommenge im letzten Jahr',
    width: '100%',
    unit: 'kWh',
    description: 'Ihr jährlicher Gesamtertrag durch die ausgewählte Anlage in kWh.',
  },
  startfeedin_gen: {
    name: 'startfeedin_gen',
    type: 'date',
    is_required: true,
    label: 'Datum der Inbetriebnahme',
    description: 'Wann ist die Anlage in Betrieb gegangen und hat ggf. Angefangen einzuspeisen?',
  },
  eeg_subsidy_old_gen: {
    name: 'eeg_gen',
    type: 'float',
    is_required: false,
    min: 1,
    max: 30,
    label: 'Falls zutreffend: EEG-Vergütung bei Einspeisestart',
    unit: 'Cent / kWh',
    description: 'Wie hoch war die EEG Vergütung bei Start der Einspeisung?',
  },
  self_use_gen: {
    name: 'self_use_gen',
    type: 'switch',
    is_required: false,
    label: 'Handelt es sich um eine Eigenverbrauchsanlage?',
    description:
      'Wird ihre Anlage zur Eigennutzung eingesetzt (ein Teil der Energie wird von Ihnen selbst verwendet) oder speist diese komplett in das Netz ein (die Energie geht zu 100% ins Netz).',
  },
  evq_gen: {
    name: 'evq_gen',
    type: 'integer',
    is_required: false,
    label: 'Eigenverbrauchsquote',
    description:
      'Welcher Anteil der generierten Strommenge wird für den Eigenverbrauch genutzt',
    unit: '%',
  },
  isUploadFiles: {
    name: 'isUploadFiles',
    type: 'switch',
    is_required: false,
    label: 'Verfügen Sie über die Lastgänge für Erzeugung und ggf. Einspeisung?',
  },
  gen_load_profile_upload: {
    name: 'gen_load_profile_upload',
    type: 'file',
    is_required: false,
    label: 'Bitte laden Sie hier Ihren Erzeugungslastgang des Vorjahres (1.1.-31.12.) hoch. Dieser sollte zwei Spalten (Zeitstempel, Ertrag) enthalten.',
    description:
      ' Der Erzeugungslastgang ist die Menge an Strom, die Ihre Anlage produziert. Wir benötigen diesen, da wir hiermit ihren Gesamtstrombedarfslastgang ermitteln, mit dem wir viele weitere Technologien für sie analyiseren können.  ',
    accept: 'xlsx, csv',
  },
  gen_load_profile_upload_second: {
    name: 'gen_load_profile_upload_second',
    type: 'file',
    is_required: false,
    label: 'Bitte laden Sie hier Ihren Einspeiselastgang des Vorjahres (1.1.-31.12.) hoch. Dieser sollte zwei Spalten (Zeitstempel, Einspeisemenge) enthalten.',
    description:
      'Der Einspeiselastang ist die Menge an Strom, die von der PV-Anlage jede 15-Minuten ins Netz zurückgespeist wird. Wir benötigen diesen, da wir hiermit ihren Gesamtstrombedarfslastgang ermitteln, mit dem wir viele weitere Technologien für sie analyiseren können.  ',
    accept: 'xlsx, csv',
  },

  /// Add to file uploaders
};
