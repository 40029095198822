import React, { FC, memo, useMemo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { FieldComponentProps } from '@/types';
import { IMaskInput } from 'react-imask';

interface Props extends FieldComponentProps {
  className?: string;
  maxLabelInput?:boolean;
  accordion?:boolean;
}

const ControlledNumericInput: FC<Props> = ({ className,maxLabelInput, accordion, ...props }) => {
  const { type, name, autoFocus, max_width, ...q } = props;
  const range = q.max ? `${q.min ?? 0}-${q.max}` : undefined;
  const onlyMin = q.min;
  const placeholder = q.placeholder ?? range ?? onlyMin ?? '';
  const style = useMemo(() => ({ ...(max_width && { maxWidth: max_width }) }), [max_width, max_width]);

  return (
    <FieldController
      name={`${q.model}.${name}`}
      q={q}
      type={type}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => {
        return (
          <div>
            <InputLabel inputStyle={style} maxLabelInput={maxLabelInput?maxLabelInput:false} className={className} q={props} accordion={accordion?accordion:false}>
              <IMaskInput
                autoFocus={autoFocus}
                radix=","
                mapToRadix={['.']}
                mask={Number}
                thousandsSeparator="."
                unmask={true}
                onAccept={(value: string) => onChange(value)}
                {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
                className="w-full rounded border border-base-300 p-3 mt-2 text-neutral-800"
                placeholder={placeholder.toString()}
                pattern={q.pattern}
                padFractionalZeros={false}
                normalizeZeros={false}
                {...field}
                value={String(field.value)}
                inputRef={ref}
              />
              {q.unit && <span className="absolute right-2 text-accent mt-[18px]">{q.unit}</span>}
            </InputLabel>
            <div className="mt-2 mb-2">{error && <InputError error={error.message} />}</div>
          </div>
        );
      }}
    />
  );
};

export default memo(ControlledNumericInput);
