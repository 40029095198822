import React, { FC, PropsWithChildren } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface Props {
  description?: string;
  className?: string;
  componenet?:string;
}

const FlowDescription: FC<PropsWithChildren<Props>> = ({ children, description, className,componenet }) => {
  return (
    <p style={componenet=='accordion'?{color:'var(--input-text-color)'}:{}} className={classNames('text-base text-accent font-semibold', { [className!]: className })}>
      {children}
      {description && (
        <span className="tooltip tooltip-right" data-tip={description}>
          <InformationCircleIcon className="icon-color" />
        </span>
      )}
    </p>
  );
};

export default FlowDescription;
