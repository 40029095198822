import React, { FC, memo } from 'react';
import InputLabel from '@components/inputs/InputLabel';
import InputError from '@components/inputs/InputError';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';

const Checkbox: FC<Field> = props => {
  return (
    <FieldController
      name={`${props.model}.${props.name}`}
      q={props}
      type={props.type}
      defaultValue={false}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => (
        <div className="mb-5">
          <InputLabel q={props} className="flex gap-2 flex-row-reverse justify-end mb-0">
            <input
              name={name}
              type="checkbox"
              id={props.name}
              className="checkbox"
              checked={value}
              onChange={e => onChange(e.target.checked)}
            />
          </InputLabel>
          <div className="mt-2 mb-2 font-normal">{error && <InputError error={error.message} />}</div>
        </div>
      )}
    />
  );
};

export default memo(Checkbox);
