import React, { FC, memo } from 'react';

import LightningLogo from './logos/LightningLogo';
import FileLogo from './logos/FileLogo';
import MainContainer from '@shared/MainContainer';
import { VITE_NODE_ENV } from '@utils/getEnv';
import CheckmarkAnimation from '@components/CheckmarkAnimation';

interface Props {
  reportUrl?: string | null;
  error?: string | null;
}

const reportFileGenerationEnabled = JSON.parse(VITE_NODE_ENV['VITE_ENABLE_REPORT_GENERATION']);

const StartLink = memo(() => (
  <div className="mt-12 flex justify-center underline input-label">
    <a href="/">Zurück zum Start</a>
  </div>
));

const Report: FC<Props> = ({ reportUrl, error }) => {
  return (
    <MainContainer className="min-h-screen">
      <div className="flex flex-col justify-center h-full">
        <LightningLogo />
        <h1 style={{ color: "var(--header)" }} className="font-bold text-[36px] mt-2">Dankeschön</h1>

        <div className="text-[20px] mt-4" style={{ color: '#6B7280' }}>
          {reportFileGenerationEnabled ? (
            <>
              <p className="mb-10">In Kürze erhalten Sie ihr individuelles Exposé zum Download.</p>
              <p>Wir simulieren verschiedene Szenarien, daher bitten wir Sie um einen Moment Geduld.</p>
            </>
          ) : (
            <>
              <p className="mb-10 input-label">Vielen Dank. In Kürze erhalten Sie ihr individuelles Exposé von uns via Email.</p>
              <p></p>
              <div className="mt-2">
                <CheckmarkAnimation />
              </div>
            </>
          )}
        </div>
        {reportFileGenerationEnabled ? (
          <>
            {!reportUrl && !error ? (
              <div className="animate-bounce h-5 my-3">
                <br />
                Exposé wird berechnet.
              </div>
            ) : (
              <>
                <div
                  className="flex flex-col items-center justify-center gap-6 w-full h-[248px]"
                  style={{
                    backgroundColor: 'var(--download-area-color)',
                    borderRadius: '5px',
                    marginTop: '24px',
                  }}
                >
                  <FileLogo />
                  <div
                    className="flex flex-col items-center justify-center max-w-[350px] w-full h-[56px] text-white"
                    style={{
                      backgroundColor: 'var(--download-button-color)',
                      fontWeight: 600,
                      borderRadius: 'var(--download-button-radius)',
                    }}
                  >
                    <a className="text-[20px]" target="_blank" download="bericht.pdf" href={reportUrl || ''}>
                      Jetzt ihr Exposé Downloaden
                    </a>
                  </div>
                </div>
                <StartLink />
              </>
            )}
          </>
        ) : (
          <StartLink />
        )}
      </div>
    </MainContainer>
  );
};

export default Report;
