import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { PageConfig } from '@/types';

interface ProgressBarProps {
  progressLength: number;
  progress: number;
  navigate?: (step: number) => void;
  maxPageProgress: number;
  currentPage: number;
  pageHistory: number[];
  maxPage: number;
  pages: PageConfig[];
}

const ProgressBar: FC<ProgressBarProps> = ({
  progressLength,
  progress,
  navigate,
  maxPageProgress,
  currentPage,
  pageHistory,
  maxPage,
  pages
}) => {
  const progressPoints = useMemo(() => new Array(progressLength).fill(0), [progressLength]);

  return (
    <div className="h-full flex flex-row gap-1 max-md:gap-0 items-center">
      {progressPoints.map((key, i) => {
        const formula = (i / progressLength) * 100;
        let title = pages[i].category;
        const notAllowed = !pageHistory.includes(i);
        const isOpen = (maxPageProgress > formula || progress > formula || currentPage === i || maxPage >= i);

        if (notAllowed) {
          title = `${title} - Übersprungen.`
        }

        return (
          <div
            data-tip={title}
            onClick={() => navigate?.(i)}
            className={classNames('w-full h-2', {
              '!cursor-not-allowed': notAllowed,
              'cursor-pointer': maxPageProgress >= formula,
              'filter brightness-125': currentPage === i,
              'tooltip': isOpen,
            })}
            key={i}
            style={{
              backgroundColor: isOpen ? 'var(--progress-primary)' : 'var(--progress-secondary)',
              borderTopLeftRadius: i === 0 ? '4px' : undefined,
              borderBottomLeftRadius: i === 0 ? '4px' : undefined,
              borderTopRightRadius: i === progressPoints.length - 1 ? '4px' : undefined,
              borderBottomRightRadius: i === progressPoints.length - 1 ? '4px' : undefined,
            }}
          />
        );
      })}
    </div>
  );
};

export default ProgressBar;
