export const sessionStorage = {
  setSession: (uuid: string) => {
    try{
      const sessions = JSON.parse(localStorage.getItem('sessions') || '[]');
      localStorage.setItem('sessions', JSON.stringify([...sessions, uuid]));
    }
    catch(e)
    {
      localStorage.setItem('sessions', JSON.stringify([uuid]));
    }
  },
  getSessions: () => {
    const sessions = localStorage.getItem('sessions');

    if (sessions) {
      try {
        return JSON.parse(sessions);
      } catch (e) {
        return [];
      }
    }

    return [];
  },
  isValid: () => {
    const sessions = sessionStorage.getSessions();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const uuid = params.get('uuid');

    return sessions.includes(uuid);
  },
};
