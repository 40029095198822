import React, { FC, memo } from 'react';
import Title from '@components/custom-flows/load-profile/Title';
import PercentLabelsYmonth from '@components/custom-flows/load-profile/PercentLabelsYmonth';
import { Controller, useFormContext } from 'react-hook-form';
import LoadProfileMonthWidget from '@components/custom-flows/load-profile/LoadProfileMonthWidget';
import { Field } from '@/types';

const MON_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

const LoadProfileMonthlyField: FC<Field> = ({ description, ...props }: any) => {
  const { control, watch } = useFormContext();

  return (
    <div style={{ color: "var(--input-text-color)" }}>
      <Title
        title={'Lastgangprofil Monat'}
        tooltip={
          'Wie produzieren Sie an einem gewöhnlichen Jahr? Bei ihrem Profil kann es sinnhaft sein Teile der Stromerzegung einzuspeisen. Daher benötigen wir Informationen zu ihrer Produktion über das Jahr hinweg.'
        }
      />

      <div className="flex flex-row max-md:overflow-x-scroll">
        <PercentLabelsYmonth />
        <Controller
          control={control}
          render={({ field, fieldState, formState }) => {
            const monthly_profile = field.value.split(',');

            return (
              <LoadProfileMonthWidget
                onChange={field.onChange}
                profile_name={field.name}
                profile={monthly_profile}
                text_labels={MON_NAMES}
              />
            );
          }}
          name={`${props.model}.${props.name}`}
        />
      </div>
    </div>
  );
};

export default memo(LoadProfileMonthlyField);
