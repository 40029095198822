import React, { FC, memo } from 'react';
import InputError from '@components/inputs/InputError';
import InputLabel from '@components/inputs/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';
import Dropdown from '@components/inputs/Dropdown';
import classNames from 'classnames';
import NumericInput from '@components/inputs/NumericInput';

interface Props extends Field {
  unitName?: string;
  className?: string;
  accordion?:boolean;
}

const CombinedInput: FC<Props> = props => {
  const { className, unitName, unit, type, name, options,accordion, ...q } = props;

  return (
    <InputLabel q={props} accordion={accordion?accordion:false}>
      <FieldController
        name={`${q.model}.${name}`}
        q={q}
        type={type}
        render={({ field, fieldState }) => {
          return (
            <div className={classNames('w-full', { [className!]: className })}>
              <div className="flex">
                <div
                  className={classNames(
                    'flex items-center gap-1 w-full rounded border border-base-300 px-3 py-2.5 mt-2 text-neutral-800 !rounded-r-none border-r-0 bg-white',
                    { 'border-error': !!fieldState.error },
                  )}
                >
                  <NumericInput
                    type={type}
                    {...q}
                    {...field}
                    value={String(field.value)}
                    error={!!fieldState.error}
                    className="text-lg flex-grow !p-0 focus:shadow-none focus:ring-0 !m-0 !border-0"
                  />

                  {unit && <span className="text-lg text-accent whitespace-nowrap">{unit}</span>}
                </div>

                {unitName && (
                  <FieldController
                    name={`${q.model}.${unitName}`}
                    q={q}
                    type="select"
                    render={({ field }) => {
                      return (
                        <Dropdown
                          {...field}
                          hidePlaceholder
                          className="min-w-[100px]  svg:size-16 text-l h-[52px] flex-grow !text-accent rounded-l-none !border-l-0"
                          arrowClassName="size-4 min-w-4"
                          error={!!fieldState.error}
                          options={options}
                        />
                      );
                    }}
                  />
                )}
              </div>
              <div className="mt-2 mb-2">{fieldState.error && <InputError error={fieldState.error.message} />}</div>
            </div>
          );
        }}
      />
    </InputLabel>
  );
};

export default memo(CombinedInput);
