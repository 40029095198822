import { FC } from 'react';
import ReactSlider from 'react-slider';

interface Props {
  onChange: (v: string) => void;
  profile_name: string;
  profile: number[];
  text_labels: (string | number)[];
}

const LoadProfileWidget: FC<Props> = ({ onChange, profile_name, profile, text_labels }) => {
  const handleSliderChange = (value: number, index: number) => {
    const updatedProfile = [...profile];
    updatedProfile[index] = value;
    onChange(updatedProfile.join(',')); // Update the parent with the new values
  };

  return (
    <div className="flex flex-col items-center space-y-4 h-36" id="profile_name">
      <div className="flex items-start h-36 w-4 gap-[5px] py-4">
        {profile.map((value, index) => (
          <div key={index} className="flex flex-col items-center w-4 relative h-28 pl-2">
            <ReactSlider
              className="vertical-slider"
              thumbClassName="thumb-sm"
              trackClassName="track-sm"
              renderThumb={(props, state) => <div {...props}>{text_labels[index]}</div>}
              orientation="vertical"
              invert
              pearling
              value={[value]}
              onChange={(v) => handleSliderChange(+v, index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadProfileWidget;
