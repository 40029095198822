import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

export const useFormLock = ({ control }: { control: Control }) => {
  const loadProfileUpload = useWatch({
    control,
    name: 'load_profile_upload',
  });

  const existingGenerators = useWatch({
    control,
    name: 'existing_generators',
  });

  const isLocked = useMemo(() => {
    const isLockedGenerators = existingGenerators?.some((g: Record<string, { isUploading: boolean }[]>) => {
      return (
        g.gen_load_profile_upload_second?.some((f: { isUploading: boolean }) => f.isUploading) ||
        g.gen_load_profile_upload?.some((f: { isUploading: boolean }) => f.isUploading)
      );
    });

    const isLockedLoadProfileUpload = loadProfileUpload?.some((f: { isUploading: boolean }) => f.isUploading);

    return isLockedGenerators || isLockedLoadProfileUpload;
  }, [loadProfileUpload, existingGenerators]);

  return { isLocked };
};
