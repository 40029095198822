import React, { ComponentProps, FC, memo, useCallback, useMemo } from 'react';
import { Accept, DropzoneOptions, FileError, FileRejection, useDropzone } from 'react-dropzone';
import UploadLogo from '@components/logos/UploadLogo';
import { filesize } from 'filesize';
import FilePreview from '@components/form/file-uploader/FilePreview';
import mime from 'mime';
import { DEFAULT_FILE_FORMATS } from '@constants/inputs';
import { Field } from '@/types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import FlowDescription from '@components/FlowDescription';

type Props = Omit<DropzoneOptions, 'accept'> &
  Field & {
    accept?: string; // 'png, jpg'
    files?: File[];
    error?: string;
    max_files?: DropzoneOptions['maxFiles'];
    max_size?: DropzoneOptions['maxSize'];
    upload_id?: string;
    className?: string;
    fileUploadedBefore?: boolean;
  };

interface CustomFieldError {
  fieldError: { message: string };
}

const generateAccept = (accept?: string) => {
  return accept?.split(', ').reduce(
    (acc, next) => {
      const type = mime.getType(next);

      if (type) {
        return {
          ...acc,
          [type]: [...(acc?.[type] || []), `.${next}`],
        };
      }

      return acc;
    },
    {} as Record<string, string[]>,
  );
};

const FileUploader: FC<Props> = ({
  error,
  multiple = false,
  max_files: maxFiles = Infinity,
  max_size: maxSize = 50,
  accept = DEFAULT_FILE_FORMATS,
  description,
  label,
  name,
  model,
  upload_id,
  className,
  ...props
}) => {
  const maxSizeBytes = maxSize * 1024 * 1024;
  const maxAllowedFiles = multiple ? maxFiles : 1;

  const { control, formState, setError, setValue, clearErrors } = useFormContext<{
    [key: typeof name]: {
      file: File;
      errors?: FileError[];
      isError?: boolean;
      isUploading?: boolean;
      errorMessage?: string;
      uploaded?: boolean;
    }[];
  }>();
  const fileUploadedBefore = useWatch({
    control,
    name: `filesLoaded.${name}`
  })

  const { fields, append, remove } = useFieldArray<{
    [key: typeof name]: {
      file: File;
      errors?: FileError[];
      isError?: boolean;
      isUploading?: boolean;
      errorMessage?: string;
      uploaded?: boolean;
    }[];
  }>({
    control,
    name,
  });

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const accepted = acceptedFiles.map(f => ({ file: f, isUploading: true })) as {
        isUploading: boolean;
        file: File;
      }[];
      const rejected = fileRejections.map(f => ({ ...f, isUploading: true })) as (FileRejection & {
        isUploading: boolean;
      })[];

      const files = [...accepted, ...rejected];
      const isMaxFilesError = fields.length + files.length > maxAllowedFiles;

      files.forEach((f, i) => {
        if ('errors' in f || isMaxFilesError) {
          if ((f as unknown as FileRejection).errors.length) {
            (f as unknown as FileRejection).errors
              .filter(e => e.code !== 'too-many-files')
              .forEach(e => {
                setError(`${name}.${i}`, {
                  type: e.code || 'custom',
                  message: e.message,
                });
              });
          }

          if (isMaxFilesError) {
            setError(`${name}.fieldError`, {
              type: 'too-many-files',
              message: 'Too many files',
            });
          }
        }
      });

      append(files);
    },
    [fields, maxAllowedFiles],
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    maxFiles: maxAllowedFiles,
    maxSize: maxSizeBytes,
    accept: generateAccept(accept) as unknown as Accept,
    onDrop,
    multiple,
    ...props,
  });

  const formStateErrors = useMemo(() => formState.errors[name], [name, formState]);

  const onCancel = useCallback(
    (index: number) => () => {
      if (fields.length - 1 <= maxAllowedFiles) {
        clearErrors(`${name}.fieldError`);
      }

      setValue(`${name}.${index}.uploaded`, false);
      setValue(`${name}.${index}.isUploading`, false);
      setValue(`${name}.${index}.isError`, false);
      remove(index);
    },
    [remove, fields, maxAllowedFiles, formStateErrors],
  );

  const fileLabel = maxAllowedFiles > 1 ? 'Dateien' : 'Datei';
  const isMaximum = fields.length >= maxAllowedFiles;
  console.log(name)
  return (
    <div className={className}>
      {label && (
        <FlowDescription componenet={name=='load_profile_upload'?'accordion':''} description={description} className="mb-5 " >
          {label}
        </FlowDescription>
      )}
      {error && <p className="text-xl	text-error font-semibold mb-6">{error}</p>}
      {fileUploadedBefore && <p className="text-xl text-success font-semibold mb-6 test-sm">
        Die Datei wurde bereits hochgeladen, Sie können das Hochladen überspringen.
      </p>}
      {(formStateErrors as unknown as CustomFieldError)?.fieldError?.message || isMaximum ? (
        <p
          style={name=='load_profile_upload'?{ color: "var(--input-text-color)"}:{}}
          className={classNames('text-base text-accent font-semibold mb-6', {
            'text-error': (formStateErrors as unknown as CustomFieldError)?.fieldError?.message,
          })}
        >
          {(formStateErrors as unknown as CustomFieldError)?.fieldError?.message
            ? 'Too many files'
            : 'Vielen Dank. Ihre Datei wird hochgeladen.'}
        </p>
      ) : (
        <div
          className={`p-9 ${fields.length && multiple ? 'h-[218px]' : 'h-[314px]'} flex items-center justify-center ${!fields.length || multiple ? 'border-2' : ''} ${isDragActive ? 'border-solid' : 'border-dashed'} hover:border-solid ${error ? 'border-error' : 'border-[var(--grey-scale-300)]'} bg-white rounded-2xl  cursor-pointer`}
          {...getRootProps()}
        >
          <input {...getInputProps()} name={name} />
          <div className="text-2xl text-[var(--input-selected)]">
            {isDragActive ? (
              <p className="text-center">Drop the {fileLabel} here ...</p>
            ) : (
              <div className="flex flex-col items-center text-center">
                <UploadLogo className="mb-2" />
                <p className="font-semibold text-base mb-6">
                  Klicken Sie, um{' '}
                  <span className="text-[var(--theme-icon-color)] underline">Ihre Dateien zu durchsuchen</span>
                  <br /> oder ziehen Sie sie per Drag & Drop hierher.
                </p>
                <p className="text-[var(--grey-scale-400)] text-[16px]">
                  {maxAllowedFiles !== Infinity && `${maxAllowedFiles} ${fileLabel} max | `}
                  {accept && <span className="uppercase">{accept} | </span>}bis zu{' '}
                  {filesize(maxSizeBytes, { standard: 'jedec' })}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {!!fields.length && (
        <div className="flex flex-col gap-4 mt-6">
          {fields.map((f, i) => {
            const { file, id, errorMessage, uploaded, isError } = f;
            return (
              <FilePreview
                uploaded={uploaded}
                key={id}
                file={file}
                error={
                  (errorMessage && { message: errorMessage }) ||
                  (formStateErrors?.[i] as ComponentProps<typeof FilePreview>['error'])
                }
                model={model}
                upload_id={upload_id}
                onError={(error: string) => {
                  setValue(`${name}.${i}.isError`, true);
                  setValue(`${name}.${i}.errorMessage`, error);
                  setValue(`${name}.${i}.isUploading`, false);
                }}
                onSuccess={() => {
                  setValue(`${name}.${i}.uploaded`, true);
                  setValue(`${name}.${i}.isUploading`, false);
                }}
                onRemove={onCancel(i)}
                onCancel={onCancel(i)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default memo(FileUploader);
