import React, { FC, memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AccordionItem from '@components/accordion/AccordionItem';
import Select from '@components/form/Select';
import { HEAT_SOURCE_FORM, MODEL } from '@components/custom-flows/heat-source/contants';
import CombinedInput from '@components/form/CombinedInput';
import { HeatSourceItem as HeatSourceItemType } from '@components/custom-flows/heat-source/types';

import ProcessHeatData from '@components/custom-flows/heat-source/ProcessHeatData';
import RemoveButton from '@components/custom-flows/heat-source/RemoveButton';
import HeatSourceFile from '@components/custom-flows/heat-source/HeatSourceFile';

interface Props {
  index: number;
  open: boolean;
  onToggle: () => void;
  removable?: boolean;
  onRemove?: () => void;
}

const INITIAL_TITLE = 'Wärmeerzeuger';

const HeatSourceItem: FC<Props> = ({ index, removable, onRemove, open, onToggle }) => {
  const { getValues, watch,setValue  } = useFormContext<{ heat_information: HeatSourceItemType[] }>();
  const [title, setTitle] = useState<string | null>(INITIAL_TITLE);

  const blockTitle = `${index + 1}. ${title}`;

  useEffect(()=>{
    if(getValues(`heat_information.${index}.unit_heat_amount`)!==getValues(`heat_information.${index}.unit_heat_cost`))
    {
      setValue(`heat_information.${index}.unit_heat_cost`,getValues(`heat_information.${index}.unit_heat_amount`))
    }
  },[watch(`heat_information.${index}.unit_heat_amount`)])

  useEffect(()=>{
    if(getValues(`heat_information.${index}.unit_heat_amount`)!==getValues(`heat_information.${index}.unit_heat_cost`))
    {
      setValue(`heat_information.${index}.unit_heat_amount`,getValues(`heat_information.${index}.unit_heat_cost`))
    }
  },[watch(`heat_information.${index}.unit_heat_cost`)])

  return (
    <AccordionItem title={blockTitle} open={open} onToggle={onToggle}>
      <Select
        category={''}
        page={''}
        model={MODEL}
        className="block mb-1 "
        onChange={value => {
          if (value) {
            const fields = getValues('heat_information').filter(field => field.source_heat === value);
            setTitle(`${value} ${fields.length > 1 ? fields.length : ''}`);
            return;
          }
          setTitle(INITIAL_TITLE);
        }}
        {...HEAT_SOURCE_FORM.source_heat}
        name={`${index}.${HEAT_SOURCE_FORM.source_heat.name}`}
        accordion
      />
      <CombinedInput
        page={''}
        model={MODEL}
        category={''}
        className="input-short"
        {...HEAT_SOURCE_FORM.total_consumption_heat}
        unitName={`${index}.${HEAT_SOURCE_FORM.total_consumption_heat.unitName}`}
        name={`${index}.${HEAT_SOURCE_FORM.total_consumption_heat.name}`}
        accordion
      />

      <CombinedInput
        page={''}
        model={MODEL}
        category={''}
        className="input-short"
        {...HEAT_SOURCE_FORM.year_consumption_heat}
        name={`${index}.${HEAT_SOURCE_FORM.year_consumption_heat.name}`}
        type='integer'
        accordion
      />

      <CombinedInput
        page={''}
        model={MODEL}
        category={''}
        className="input-short"
        {...HEAT_SOURCE_FORM.cost_unit_heat}
        unitName={`${index}.${HEAT_SOURCE_FORM.cost_unit_heat.unitName}`}
        name={`${index}.${HEAT_SOURCE_FORM.cost_unit_heat.name}`}
        accordion
      />

      <CombinedInput
        page={''}
        model={MODEL}
        category={''}
        className="input-short"
        {...HEAT_SOURCE_FORM.construction_year_heat}
        name={`${index}.${HEAT_SOURCE_FORM.construction_year_heat.name}`}
        type='integer'
        accordion
      />

      <CombinedInput
        page={''}
        model={MODEL}
        category={''}
        className="input-short"
        {...HEAT_SOURCE_FORM.heating_limit_temperature}
        name={`${index}.${HEAT_SOURCE_FORM.heating_limit_temperature.name}`}
        accordion
      />

      <hr className="border-t-2 mt-3 mb-4 border-[--grey-scale-200]" />
      <ProcessHeatData index={index} />

      <hr className="border-t-2 mt-3 mb-4 border-[--grey-scale-200]" />
      <HeatSourceFile index={index} />

      {removable && onRemove && <RemoveButton block={blockTitle} onRemove={onRemove} />}
    </AccordionItem>
  );
};

export default memo(HeatSourceItem);
