import { HEAT_UNITS } from '@/constants';

export const MODEL = 'heat_information';

export const HEAT_SOURCE_FORM = {
  source_heat: {
    name: 'source_heat',
    type: 'select',
    is_required: true,
    placeholder: 'Bitte wählen Sie',
    label: 'Wie erzeugen Sie die Wärme?',
    description: 'Description',
    options: [
      'Erdgas',
      'Flüssiggas',
      'Öl',
      'Wärmepumpe',
      'Solarthermie',
      'Fernwärme',
      'Propan',
      'Butan',
      'Elektrische Heizung (Direkumwandlung)',
      'Dezentrale Lüftung/Heizung (Gasbetrieben)',
      'Dezentrale Lüftung/Heizung (Wärmepumpenbetrieben)',
      'Dezentrale Wärmestrahler (Gasbetrieben)',
      'Dezentrale Wärmestrahler (Elektrisch)',
      'Geothermie',
      'Biomasse',
      'Wasserstoff',
      'Holzpellets',
      'Diesel',
      'Biodiesel',
    ],
  },
  total_consumption_heat: {
    name: 'total_consumption_heat',
    type: 'float',
    is_required: true,
    min: 0,
    label: 'Wärmemenge und Einheit',
    unitName: 'unit_heat_amount',
    options: HEAT_UNITS,
  },
  year_consumption_heat: {
    name: 'year_consumption_heat',
    type: 'text',
    is_required: true,
    min: 2000,
    label: 'Jahr der Wärmemenge',
    max: 2025,
    min_err: "Das niedrigste Jahr ist 2000.",
    max_err: "Das höchste Jahr ist 2025.",
    pattern: "^[0-9]{4}$",
    pattern_err: "Stellen Sie sicher, dass Sie eine korrekte Zahl eingeben."
  },
  cost_unit_heat: {
    name: 'cost_unit_heat',
    type: 'float',
    is_required: true,
    min: 0,
    label: 'Wärmepreis und Einheit',
    unitName: 'unit_heat_cost',
    options: HEAT_UNITS,
    unit: 'Cent /',
  },
  construction_year_heat: {
    name: 'construction_year_heat',
    type: 'text',
    is_required: false,
    min: 1975,
    label: 'Installationsjahr des Wärmeerzeugers (Optional)',
    max: 2025,
    min_err: "Das niedrigste Jahr ist 1975.",
    max_err: "Das höchste Jahr ist 2025.",
    pattern: "^[0-9]{4}$",
    pattern_err: "Stellen Sie sicher, dass Sie eine korrekte Zahl eingeben."
  },
  heating_limit_temperature: {
    name: 'heating_limit_temperature',
    type: 'float',
    is_required: false,
    min: 0,
    label: 'Heizgrenztemperatur (Optional)',
    description: "Die Heizgrenztemperatur ist die Außentemperatur, ab der die Heizung in Betrieb geht.",
    unit: '°C',
  },
  isGenerateProcessHeat: {
    name: 'isGenerateProcessHeat',
    type: 'switch',
    is_required: false,
    label: 'Erzeugen Sie hiermit auch Prozesswärme?',
  },
  max_temp_process_heat: {
    name: 'max_temp_process_heat',
    type: 'integer',
    is_required: true,
    min: 0,
    label: 'Maximaltemperatur',
    unit: '°C',
  },
  max_power_process_heat: {
    name: 'max_power_process_heat',
    type: 'integer',
    is_required: true,
    min: 0,
    label: 'Maximalleistung',
    unit: 'kW',
  },
  fluid_type_process_heat: {
    name: 'fluid_type_process_heat',
    type: 'select',
    is_required: true,
    label: 'Wärmeträgermedium',
    options: ['Wasser', 'Dampf', 'Thermo-öl', 'Andere'],
  },
  isUploadFile: {
    name: 'isUploadFile',
    type: 'switch',
    is_required: false,
    label: 'Verfügen Sie über einen Lastgang?',
  },
  loadprofile: {
    name: 'loadprofile',
    type: 'file',
    is_required: false,
    label: 'Bitte laden Sie hier Ihren Lastgang (01.01.-31.12.) hoch. Dieser sollte zwei Spalten (Zeitstempel, Verbräuche) enthalten.',
    accept: "xlsx, csv",
    description:
      ' Der Lastgang ist die Menge an Energie, die Ihre Anlage benötigt - beispielsweise in kWh. Wir benötigen diesen, da wir damit eine noch bessere Analyse Ihres Heizungssystems durchführen können.  ',
  },
};
